import React, { useEffect } from 'react';

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <section className="our-courses-section gap" style={{backgroundImage: "url(assets/img/courses-back.png)"}}>
            <div className="container justify-content-center align-items-center">
                <div className="heading">
                    <h2>Terms And Services</h2>
                    <p>The Quranicnoor is a non-profit organization that strives to empower everyone to benefit from the Quran.</p>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card shadow-sm rounded-md">
                            <div className="card-body mb-0">
                                <h4 className='fs-4 fw-bold mb-2'>Information Collection</h4>
                                <p  className='mb-1 term'>We collect certain personal information from users who choose to create an account on Quran.com. This information may include:</p>
                                <ul  className='mb-2 term'>
                                    <li>Email Address: We collect your email address to facilitate the account creation process and for communication purposes related to your account, including account recovery and security notifications.</li>
                                </ul>

                                <h4 className='fs-4 fw-bold mb-2'>Use of Personal Information</h4>
                                <p className='mb-2 term'>We use the personal information we collect for the following purposes:</p>
                                <ul className='mb-2 term'>
                                    <li>Account Creation and Management: Your email address is used to create and manage your account on our website. It lets you log in, access your settings, and store your bookmarks and reading history.</li>
                                    <li>Personalization: We may use your reading history to provide personalized recommendations and suggestions based on your preferences and interests.</li>
                                </ul>

                                <h4 className='fs-4 fw-bold mb-2'>Log Data</h4>
                                <p  className='mb-2 term'>We collect information that your browser sends whenever you visit our Service (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Communication</h4>
                                <p className='mb-2 term'>We may use your email address to send you important updates, newsletters, or notifications related to our services, content, etc. You will have the ability to unsubscribe from these communications at any time.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Data Protection Rights</h4>
                                <p className='mb-2 term'>The right to access: You can request the Quranicnoor for copies of your data. We may charge you a small fee for this service.</p>
                                <p className='mb-2 term'>The right to rectification: You have the right to request that the Quranicnoor correct any information you believe is inaccurate. You also have the right to request the Quranicnoor to complete the information you believe is incomplete.</p>
                                <p className='mb-2 term'>The right to erasure: You have the right to request that the Quranicnoor erase your data, under certain conditions.</p>
                                <p  className='mb-2 term'>The right to restrict processing: You have the right to request that the Quranicnoor restrict the processing of your data, under certain conditions.</p>
                                <p  className='mb-2 term'>The right to object to processing: You have the right to object to the Quranicnoor processing of your data, under certain conditions.</p>
                                <p  className='mb-2 term'>The right to data portability: You have the right to request that the Quranicnoor transfer the data that we have collected to another organization, or directly to you, under certain conditions.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Data Security</h4>
                                <p  className='mb-2 term'>We take appropriate measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. We use industry-standard security protocols and employ physical, electronic, and managerial safeguards to ensure the confidentiality and integrity of your data.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Data Sharing</h4>
                                <p  className='mb-2 term'>We do not sell, trade, or rent your personal information to third parties.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Data Analysis</h4>
                                <p  className='mb-2 term'>We use Google Analytics to ensure that the site continues to work as expected and to know which features to prioritize working on, etc. This information is anonymous and we don’t trace it back to any particular individual.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Data Deletion</h4>
                                <p  className='mb-2 term'>To ensure your privacy and control over your personal information, we provide a straightforward account deletion process. When you choose to delete your account, all associated personal data will be automatically and permanently removed from our systems. You can initiate the account deletion by accessing your profile page. Once the deletion is initiated, your data will be securely deleted from our servers within a reasonable timeframe.</p>

                                <h4 className='fs-4 fw-bold mb-2'>Use of Cookies</h4>
                                <p  className='mb-2 term'>Quran.com employs cookies to enhance your browsing experience, provide personalized content, and analyze website traffic. By accessing and using our website, you consent to the use of cookies by these Terms And Services.</p>
                                <h5 className='mb-2'>Types of Cookies We Use:</h5>
                                <ul className='mb-2  term'>
                                    <li className='term mb-1'>Necessary Cookies: These cookies are essential for the proper functioning of our website and enable basic features, such as page navigation and access to secure areas. They do not collect any personally identifiable information. Without these cookies, some parts of the website may not function correctly.</li>
                                    <li  className='term'>Analytical and Performance Cookies: We utilize these cookies to gather information about how visitors use our website, including the number of visitors, the pages they visit, and the time spent on each page. This data helps us analyze and improve the performance and functionality of our website. These cookies do not identify you personally; all data is aggregated and anonymous.</li>
                                </ul>

                                <h4 className='fs-5 fw-bold mb-2'>Contact Us</h4>
                                <p  className='mb-2 term'>If you have any questions, concerns, or requests regarding these Terms And Services or the handling of your personal information, please contact us <a href="#">here</a>. You may even contact us at our Email address: <a href="mailto:abubakrnaeembutt@gmail.com">abubakrnaeembutt@gmail.com</a>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Terms;
