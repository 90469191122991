import React, { useEffect,  useState } from 'react'
import { useDispatch } from 'react-redux';
import useAppSelector from '../../Redux/Utilities/useAppSelector';
import List from './List';
import AudioPlay from './AudioPlay';
import SurahList from '../Reading/SurahList';
import {  fetchReading } from '../../Redux/Slices/QuranReading';
import { fetchRecitersList } from '../../Redux/Slices/RecitersName';

const NewAudio = () => {
  const dispatch = useDispatch();
  const { recitersName } = useAppSelector((state) => state.recitersName);
  const { ReadingData } = useAppSelector((state) => state.ReadingData);
  const surahs = ReadingData?.surahs;
  


  const [selectedSurah, setSelectedSurah] = useState(surahs?.number === 1);
  const [selectedReciter, setSelectedReciter] = useState(recitersName?.length > 0 ? recitersName?.name : '' );
  


  useEffect(() => {
    dispatch(fetchReading());
    dispatch(fetchRecitersList());

  }, [dispatch])
  
  useEffect(() => {
    // Set default values if available
    if (surahs && surahs.length > 0) {
      setSelectedSurah(surahs[0]);
    }
    if (recitersName && recitersName.length > 0) {
      setSelectedReciter(recitersName[0]);
    }
  }, [surahs, recitersName]);


  return (
    <section className="gap" id="audio">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4  card reading_surah p-2 ">
            <div className="card">
              <ul className="nav nav-tabs border-0 p-2" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="active btn tab-btn text-white" id="audio-sura" data-bs-toggle="tab" data-bs-target="#audio_sura" type="button" role="tab" aria-controls="audio-sura" aria-selected="true">Show Surahs</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="btn tab-btn" id="qari-tab" data-bs-toggle="tab" data-bs-target="#audio_list" type="button" role="tab" aria-controls="audio_list" aria-selected="false">Show Qari</button>
                </li>
              </ul>
            </div>
            <div className="tab-content  mt-3 mt-md-5 mb-3 mb-md-0" id="myTabContent">
              <div className="tab-pane fade show active" id="audio_sura" role="tabpanel" aria-labelledby="audio-sura">
                <SurahList surahList={surahs} setSelectedSurah={setSelectedSurah} selectedSurah={selectedSurah}  />
              </div>
              <div className="tab-pane fade" id="audio_list" role="tabpanel" aria-labelledby="qari-tab">
                <List recitersList={recitersName} onSelectedReciter={setSelectedReciter} defaultSelectedReciters={1} />
              </div>
            </div>


          </div>
          <div className="col-12 col-md-8 col-lg-8  mt-3 mt-md-0" >
          
              <AudioPlay surahs={surahs} setSelectedSurah={setSelectedSurah}   selectedSurah={selectedSurah} selectedReciter={selectedReciter}  />

           

          </div>

        </div>
      </div>
    </section >
  )
}

export default NewAudio