const sparah =[
    {
    id:1,
    englishName: "Alif Lam Meem",
    arabicName: "الم"
   },
    {
    id:2,
    englishName: "Sayaqool",
    arabicName: "سَيَقُولُ"
   },
    {
    id:3,
    englishName: "Tilkal Rusul",
    arabicName: "تِلْكَ ٱلرُّسُلُ "
   },
    {
    id:4,
    englishName: "Kul lu al-ta'am",
    arabicName: "كُلُّ الطَّعَامِ"
   },
    {
    id:5,
    englishName: "Wal Mohsanat",
    arabicName: "وَٱلْمُحْصَنَاتُ"
   },
    {
    id:6,
    englishName: "La Yuhibbullah",
    arabicName: "لَا يُحِبُّ ٱللهُ"
   },
    {
    id:7,
    englishName: "Wa Iza Samiu",
    arabicName: "وَإِذَا سَمِعُوا"
   },
    {
    id:8,
    englishName: "Wa Lau Annana",
    arabicName: "وَلَوْ أَنَّنَا"
   },
    {
    id:9,
    englishName: "Qāla ’l-mala’u",
    arabicName: "قَالَ ٱلْمَلَأُ"
   },
    {
    id:10,
    englishName: "Wa-’aʿlamū",
    arabicName: "وَٱعْلَمُواْ"
   },
    {
    id:11,
    englishName: "Yatazeroon",
    arabicName: "يَعْتَذِرُونَ"
   },
    {
    id:12,
    englishName: "Wa Mamin Da'abat",
    arabicName: "وَمَا مِنْ دَآبَّةٍ"
   },
    {
    id:13,
    englishName: "Wa Ma Ubrioo",
    arabicName: "وَمَا أُبَرِّئُ"
   },
    {
    id:14,
    englishName: "Rubama",
    arabicName: "رُبَمَا"
   },
    {
    id:15,
    englishName: "Subhanallazi",
    arabicName: "سُبْحَانَ ٱلَّذِى"
   },
    {
    id:16,
    englishName: "Qal Alam",
    arabicName: "قَالَ أَلَمْ"
   },
    {
    id:17,
    englishName: "Aqtarabo",
    arabicName: "ٱقْتَرَبَ لِلْنَّاسِ"
   },
    {
    id:18,
    englishName: "Qadd Aflaha",
    arabicName: "قَدْ أَفْلَحَ"
   },
    {
    id:19,
    englishName: "Wa Qalallazina",
    arabicName: "وَقَالَ ٱلَّذِينَ"
   },
    {
    id:20,
    englishName: "A'man Khalaq",
    arabicName: "أَمَّنْ خَلَقَ"
   },
    {
    id:21,
    englishName: "Utlu Ma Oohi",
    arabicName: "أُتْلُ مَاأُوْحِیَ"
   },
    {
    id:22,
    englishName: "Wa Manyaqnut",
    arabicName: "وَمَنْ يَّقْنُتْ"
   },
    {
    id:23,
    englishName: "Wa Mali",
    arabicName: "وَمَآ لي"
   },
    {
    id:24,
    englishName: "Faman Azlam",
    arabicName: "فَمَنْ أَظْلَمُ"
   },
    {
    id:25,
    englishName: "Elahe Yuruddo",
    arabicName: "إِلَيْهِ يُرَدُّ"
   },
    {
    id:26,
    englishName: "Ha'a Meem",
    arabicName: "حم"
   },
    {
    id:27,
    englishName: "Qala Fama Khatbukum",
    arabicName: "قَالَ فَمَا خَطْبُكُم"
   },
    {
    id:28,
    englishName: "Qadd Sami Allah",
    arabicName: "قَدْ سَمِعَ ٱللهُ"
   },
    {
    id:29,
    englishName: "Tabāraka ’lladhī",
    arabicName: "تَبَارَكَ ٱلَّذِى"
   },
    {
    id:30,
    englishName: "Amma Yatasa'aloon",
    arabicName: "عَمَّ يَتَسَاءَلُونَ"
   },


]
export default sparah;