import React, { useEffect } from 'react'

const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <section className="our-courses-section gap" style={{ backgroundImage: "url(assets/img/courses-back.png)" }}>
            <div className="container justify-content-center align-items-center">
                <div className="heading">
                    <h2>Privacy Policy </h2>
                    <p>Welcome to the Privacy Policy of Quranicnoor.</p>
                </div>
                <div className="row">
                    <div className="col-12 col-md-12">
                        <div className="card shadow-sm rounded-md">
                            <div className="card-body mb-0">
                                <h4 className='fs-4 fw-bold mb-2'>Privacy Policy</h4>
                                <p className='mb-1 term'>
                                    Welcome to the Privacy Policy of Quranicnoor, a studio of designers, developers, and artists producing a suite of applications and websites for the next generation of computing. Privacy Policy is committed to protecting your privacy. We have composed this Privacy Policy to describe our practices regarding the personal information (defined below) we collect from users through our website, located at our site, mobile and tablet applications, and services made available through the Site and Applications. Capitalized terms not otherwise described in this Privacy Policy have the meanings given in our Terms of Use located at Terms.

                                </p>

                                <h4 className='fs-4 fw-bold mb-2'>WHAT WE COLLECT:</h4>
                                <p className='mb-2 term'>Before we get started, I suggest defining some terms. “Personal Information” means information that allows someone to identify or contact you, including, for example, your name, telephone number, and e-mail address, and any other non-public information about you that is associated with or linked to any of the preceding Information. “Anonymous Data” means Information that is not associated with or linked to your Personal Information and does not, by itself, allow the identification of individual persons. We collect data about you in a range of ways.                                </p>


                                <h4 className='fs-4 fw-bold mb-2'>INFORMATION YOU GIVE US:</h4>
                                <p className='mb-2 term'>
                                    We gather the information you directly give us on our app. For example, if you are seeking technical support, we collect the information that you supply to us when we provide technical support. At your option, we may collect your email address so that we can send you information about our products and services. You may select not to receive this information at any time by informing us at abubakrnaeembutt@gmail.com

                                </p>

                                <h4 className='fs-4 fw-bold mb-2'>INFORMATION IS AUTOMATICALLY COLLECTED:</h4>
                                <p className='mb-2 term'>On the Spot, we automatically log data about you and your computer. For instance, when visiting our Site, we log your computer operating system type, browser type, browser language, the website you visited before browsing our Site, pages you viewed, how long you consumed on a page, access times, Internet protocol address and information about your use of and actions on our Site.
                                </p>

                                <h4 className='fs-4 fw-bold mb-2'>Cookies:</h4>
                                <p className='mb-2 term'>On the Spot, we may log information using “cookies”. Cookies are remote data files stored on your hard drive by a website. Cookies permit us to make our Site and your visit nice. We use cookies to see which features of our Site people use and like and to measure visits to our Site.                               .</p>


                                <h4 className='fs-4 fw-bold mb-2'>Web Beacons:</h4>
                                <p className='mb-2 term'>We may log attributes using digital images called Web beacons on our Site or in our emails. We use Web beacons to control cookies, count visits, and learn what marketing works and what does not. We also use Web Beacons to tell if you open or act on our emails.
                                    .</p>

                                <h4 className='fs-4 fw-bold mb-2'>USE OF PERSONAL INFORMATION</h4>
                                <p className='mb-0 term'>We use your personal information as follows:</p>

                                <ul className='mb-2  term'>
                                    <li className='term '>We use your data to operate, maintain, and improve our sites, products, and services.</li>
                                    <li className='term'>We use your data to respond to comments and questions and provide customer service.
                                    </li>
                                    <li className='term'>We use your data to send support and administrative messages.
                                    </li>
                                    <li className='term'>We use your data to communicate promotions, upcoming events, and other news about products and services offered by us and our selected partners.
                                    </li>
                                    <li className='term'>We use your data to protect, investigate, and deter fraudulent, unauthorized, or illegal activity.
                                    </li>
                                </ul>
                                <h4 className='fs-4 fw-bold mb-2'>WHAT WE DO NOT COLLECT.</h4>
                                <p className='mb-2 term'>Your photos. Your photo may be uploaded to our server to process, but we do not store them longer than the time it is needed to process. The photo will be deleted instantly after processing and we do not collect or share them with any third parties.
                                </p>
                                <h4 className='fs-4 fw-bold mb-2'>SHARING OF PERSONAL INFORMATION:</h4>
                                <p className='mb-0 term'>We may share personal information as follows:
                                </p>
                                <ul className='mb-2  term'>
                                    <li className='term '>We may share personal information with your consent. For example, through the Application, you can send messages to others.                                    </li>
                                    <li className='term'>We may share personal data for legal, protection, and safety purposes.
                                    </li>
                                    <li className='term'>We may share data to comply with laws.
                                    </li>
                                    <li className='term'>We may share data to respond to lawful requests and legal processes.

                                    </li>
                                    <li className='term'>We may share data to protect the rights and property of Tap Universe, our agents, customers, and others. This includes implementing our agreements, policies, and terms of use.
                                    </li>
                                    <li className='term'>We may share data in an emergency. This includes defending the safety of our employees and agents, our customers, or any person.

                                    </li>
                                    <li className='term'>We may transfer data to those who need it to do work for us.
                                    </li>
                                    <li className='term'>We may also transfer aggregated and anonymized data with others for their use.
                                    </li>
                                </ul>
                                <h4 className='fs-4 fw-bold mb-2'>INFORMATION CHOICES AND CHANGES:</h4>
                                <p className='mb-2 term'>Our marketing emails inform you how to “opt out.” If you opt-out, we may always send you non-marketing emails. Non-marketing emails include emails about your accounts and our business dealings with you.
                                </p>
                                <p className='mb-2 term'>You may send requests about personal data to our Contact Information below. You can ask to change communication choices, opt out of sharing with others, and correct your personal information. </p>
                                <p className='mb-2 term'>
                                    You can typically remove and reject cookies from our Site with your browser settings. Multiple browsers are set to receive cookies until you change your settings. If you dismiss or reject our cookies, it could affect how our Site works for you.
                                </p>

                                <h4 className='fs-4 fw-bold mb-2'>SECURITY OF YOUR PERSONAL INFORMATION:</h4>
                                <p className='mb-2 term'>We take steps to support and protect personal information. No company can fully prevent security threats, however. Mistakes may happen. Bad actors may defeat even the best protection.
                                </p>
                                <h4 className='fs-4 fw-bold mb-2'>CONTACT INFORMATION:</h4>
                                <p className='mb-2 term'>We welcome your remarks or questions about this Privacy Policy. You may even contact us at our Email address: abubakrnaeembutt@gmail.com
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Policy