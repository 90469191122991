import React, { useEffect, useState } from 'react';
import QuickFlipBook from './QuickFlipBook';
import SurahList from './SurahList';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../Redux/Utilities/useAppSelector';
import { fetchEnglishReading, fetchParaReading, fetchReading, } from '../../Redux/Slices/QuranReading';
import Showpara from './Showpara';
import SparaFlipBook from './SparaFlipBook';
import { fetchJuzReading } from '../../Redux/Slices/SparaSelected';
import FormSelect from './FormSelect';
import ReadingForm from '../common/Form/ReadingForm';

const Reading = () => {
    const dispatch = useDispatch();
    const { ReadingData, sparah, englishData } = useAppSelector((state) => state.ReadingData);
   

    const surahs = ReadingData?.surahs;

    const [selectedSurah, setSelectedSurah] = useState(surahs?.number === 1);
    const [paraSelected, setParaSelected] = useState(null);
    const [selectedAyatNumber, setSelectedAyatNumber] = useState(null);
    const [selectedFontSize ,   setSelectedFontSize] = useState(null);
   const [translationGet , setTranslation] = useState('none');
    // console.log("main selected fontSize" , selectedFontSize);
    // console.log("main transaltion get" , translationGet);
   

    useEffect(() => {
        dispatch(fetchReading());
        dispatch(fetchEnglishReading());
        dispatch(fetchParaReading());
    }, [dispatch]);


    useEffect(() => {
        if (surahs?.length > 0) {
            setSelectedSurah(surahs[0]);
           
        }
    }, [surahs]);

    const handleAyatSelect = (ayatNumber) => {
        setSelectedAyatNumber(ayatNumber);
       
    };

    const handleJuzSelectNumber = (juzNumber) => {
        setParaSelected(juzNumber);
        setSelectedAyatNumber(null);
       
        
    };

    useEffect(() => {
        if (paraSelected) {
            setSelectedSurah(null);
            dispatch(fetchJuzReading(paraSelected));
          
        }
    }, [paraSelected]);
    
    useEffect(() => {
        if (selectedSurah) {
            setParaSelected(null);
            
        }
    }, [selectedSurah]);
    
    const handlefontNumber = (fontNumber) => {
        setSelectedFontSize(fontNumber)
    }
    const handleTrannslation = (value)=> {
        setTranslation(value);

    }
    


    return (
        <section className='gap mb-3 bg_color' id="reading"> 
            <div className="container">
                <div className="heading">
                    <p>Read Holy Quran with</p>
                    <h2>Translation of every Ayah</h2>
                </div>
                <div className='row'>
                    <div className="col-12 col-md-4 col-lg-4 card reading_surah p-2">
                        <div className="card">
                            <ul className="nav nav-tabs border-0 p-2" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="active btn tab-btn text-white" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Show Surahs</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="btn tab-btn" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Show Juz</button>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content mt-3 mt-md-5" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <SurahList surahList={surahs} setSelectedSurah={setSelectedSurah}  selectedSurah={selectedSurah}  />
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                <Showpara para={sparah} onParaSelected={setParaSelected}  selectedPara={paraSelected}/>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-8 col-lg-8 mt-3 mt-md-0">
                        <ReadingForm 
                        onTransaltionSelect={handleTrannslation}  
                        surahList={surahs}
                        setSelectedSurah={setSelectedSurah}  
                        selectedSurah={selectedSurah} 
                        onParaSelected={setParaSelected}
                        selectedPara={paraSelected}
                        para={sparah}
                        onAyatSelect={handleAyatSelect}
                        onjuzSelect={handleJuzSelectNumber}
                        onFontSelect={handlefontNumber}
                       
                        />
                   
                        {paraSelected? <SparaFlipBook 
                        ayat={paraSelected ? paraSelected.ayahs : []} 
                        onAyatSelect={selectedAyatNumber} 
                        translationValue= {translationGet}
                        fontSelect ={selectedFontSize}

                        /> : <QuickFlipBook
                            ayat={selectedSurah ? selectedSurah.ayahs : []}
                            surahNumber={selectedSurah ? selectedSurah.number : null}
                            onAyatSelect={selectedAyatNumber}
                            arabicData={ReadingData}
                            englishData={englishData}
                            fontSelect ={selectedFontSize}
                            translationValue= {translationGet}
                        />}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Reading;
