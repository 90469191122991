import { map } from 'lodash';
import React, { useEffect, useState } from 'react';

const List = ({ recitersList, onSelectedReciter, defaultSelectedReciters }) => {
  const [selectedReciter, setSelectedReciter] = useState(null);

  useEffect(() => {
    if (recitersList && recitersList.length > 0 && defaultSelectedReciters) {
      const defaultReciter = recitersList.find((item) => item.id === defaultSelectedReciters);
      if (defaultReciter) {
        setSelectedReciter(defaultReciter.id);
        onSelectedReciter(defaultReciter); 
      }
    }
  }, [recitersList, defaultSelectedReciters, onSelectedReciter]);

  const handleReciterClick = (reciter) => {
    setSelectedReciter(reciter.id);
    onSelectedReciter(reciter); 
  };

  return (
    <div className=''>
      {map(recitersList, (item, index) => (
        <div key={item.id} className={`card bg-card mb-3 p-3 ${selectedReciter === item?.id ? 'bg-surahcard text-white' : 'bg-light'}`}
          onClick={() => handleReciterClick(item)}>
          <div className="row">
            <div className="col-2">
              <p className={`badge ${selectedReciter === item.id ? 'active_bages text-white' : 'bg_bages'} pt-3 pb-3`} style={{ width: '45px' }}>
                {index + 1}
              </p>
            </div>
            <div className="col-9 ">
              <h5 className={`fs-6 mb-0 mx-2 ${selectedReciter === item.id ? 'list_test fs-5 text-white' : ''}`}>
                {item?.name}
              </h5>
              <span className={`text-sm libraryspan mx-2 ${selectedReciter === item?.id ? 'text-white' : ''}`}>
                ({item?.moshaf[0].surah_total})
              </span>
              <p></p>
            </div>
            <div className="col-2">
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default List;
