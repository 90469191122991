import React from 'react'
import { IntroSection, 
  PrayerTiming, 
  Slider ,Feature , Progress, Pillers ,AllCourse ,
  Ayat,Navigating,Download,
  Reading,
  NewAudio
  
} from '../../components'

const Home = () => {
  return (
    <>
      
      <PrayerTiming />
      <IntroSection />
      <Feature />
      {/* <Audio /> */}
      <NewAudio />
      <Reading />
      <Progress />
      <Pillers />
      <AllCourse />
      <Ayat />
      {/* <Navigating /> */}
      <Download />
     
    </>
  )
}

export default Home