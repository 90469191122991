/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const Pillers = () => {
  return (
    <section className="gap">
        <div className="container">
            <div className="heading">
                <img src="assets/img/favicon.png" alt="icon" />
                <p>Islamic classNamees and Courses</p>
                <h2>Pillars of Islam </h2>
            </div>
            <div className="pillars">
                <div className="pillars-name">
                    <figure><img src="assets/img/pillars-name-1.png" alt="img" /></figure>
                    <img src="assets/img/pillars-1.jpg" alt="img" />
                    <a >Shahadah</a>
                    <span>( Faith )</span>
                </div>
                <div className="pillars-name">
                    <figure><img src="assets/img/pillars-name-2.png" alt="img" /> </figure>
                    <img src="assets/img/pillars-2.jpg" alt="img" />
                    <a>Salah</a>
                    <span>( Prayer )</span>
                </div>
                <div className="pillars-name">
                    <figure><img src="assets/img/pillars-name-3.png" alt="img" /></figure>
                    <img src="assets/img/pillars-3.jpg" alt="img" />
                    <a>Sawm</a>
                    <span>( Fasting )</span>
                </div>
                <div className="pillars-name">
                    <figure><img src="assets/img/pillars-name-4.png" alt="img" /></figure>
                    <img src="assets/img/pillars-4.jpg" alt="img" />
                    <a>Zakat</a>
                    <span>( Almsgiving )</span>
                </div>
                <div className="pillars-name">
                    <figure><img src="assets/img/pillars-name-5.png" alt="img" /></figure>
                    <img src="assets/img/pillars-5.jpg" alt="img" />
                    <a>Hajj</a>
                    <span>( Pilgrimage )</span>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Pillers