import React, { useState } from 'react';

const DropDowns = ({ children, options }) => {
    const [openDropDown, setOpenDrop] = useState(false);

    const onClickShow = () => {
        setOpenDrop(!openDropDown);
    }

    return (
        <div className="dropdown-container">
            <button
                className='form-control dropdown-toggle dropdown-section d-flex w-100 justify-content-between mb-3'
                onClick={onClickShow}
            >
                {children}
            </button>

            {openDropDown && (
                <div className="card p-2 p-sm-2">
                    {options}
                </div>
            )}
        </div>
    );
}

export default DropDowns;
