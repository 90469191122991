import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { fetchRequest } from "../Utilities/Request";

const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const fetchPrayerTimings = async (latitude, longitude, date) => {
    try {
      const response = await fetch(`https://api.aladhan.com/v1/timings/${date}?latitude=${latitude}&longitude=${longitude}&method=1`);
      const data = await response.json();
      return data.data;
    } catch (error) {
      console.log("error get", error);
      return [];
    }
  };

  export const FetchPrayer = createAsyncThunk('prayer/list', async () => {
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const today = new Date();
      const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
      return await fetchPrayerTimings(latitude, longitude, date);
    } catch (error) {
      console.log("Geolocation error:", error);
      // Provide a default location if geolocation fails
      const defaultLatitude = 34.359688;
      const defaultLongitude = 73.471054;
      const today = new Date();
      const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
      return await fetchPrayerTimings(defaultLatitude, defaultLongitude, date);
    }
  });

const initialState={
    loading: null,
    prayer:[]
}
const PrayerSlice =  createSlice({
    name: 'prayer',
    initialState,
    reducers:{},
    extraReducers: (builder)=>{
        builder.addCase(FetchPrayer.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(FetchPrayer.fulfilled , (state,action) =>{
            state.prayer = action.payload;
            state.loading = false;
        });
        builder.addCase(FetchPrayer.rejected , (state)=>{
            state.loading = false;
        });

        
    }

});
export default PrayerSlice;