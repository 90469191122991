import React, { useEffect, useState, useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const AudioPlay = ({ surahs, selectedSurah, selectedReciter, setSelectedSurah }) => {
  const audioRef = useRef(null);
  const [audioSrc, setAudioSrc] = useState('');
  const [toastGet, setToastGet] = useState(false);
  const [errorMessage, setErrorMessage] = useState("This Qari has no surah available");
 

  const selectedSurahArray = selectedReciter?.moshaf?.[0]?.surah_list?.split(',').map(Number) || [];
  const surahGet = selectedSurahArray.includes(selectedSurah?.number);
  
  useEffect(() => {
    
    if (selectedReciter && selectedSurah && surahGet) {
      const surahId = selectedSurah.number.toString().padStart(3, '0');
      const audioUrl = `${selectedReciter.moshaf[0]?.server || ''}${surahId}.mp3`;
      setAudioSrc(audioUrl);
      setToastGet(false);
    } else {
      setToastGet(true);
      setErrorMessage("This Qari does not have the selected surah available.");
    }
  }, [selectedReciter, selectedSurah]);  // Dependency array for useEffect

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.audio.current.src = audioSrc;
    }
  }, [audioSrc]);  // Dependency array for useEffect

  


  const handleBothFunction = (direction) => {
    const newNumber = direction === 'next' ? selectedSurah?.number + 1 : selectedSurah?.number - 1 ;
    const newSurah = surahs.filter(item => item.number === newNumber)[0];
      setSelectedSurah(newSurah)
  }

  const handleRepeat = () => {
    if (audioRef.current) {
      audioRef.current.audio.current.play();
    }
  };

  return (
    <> 
      {toastGet && (
        <div className="alert alert-danger alert-sm">
          {errorMessage}
        </div>
      )}
      <div className="pt-3 pt-md-5 pb-3 pb-md-5 justify-content-center align-items-center audio_imagesec" style={{ backgroundImage: "url(assets/img/audio2.png)", backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="text-center mt-3 mt-md-5 pt-3 pt-md-5 pb-3 pb-md-4">
          <img src="assets/img/text-circle.png" alt="Qari Image" className='object-fit-contain image_width' />
          <h3 className='text-white fw-bold'>{selectedReciter?.name}</h3>
          <p className='text-white fs-6'>{selectedSurah?.englishName} ({selectedSurah?.englishNameTranslation})</p>
        </div>
        <div className="px-2 px-md-5 mx-2 mx-md-3 justify-content-center align-item-center">
          <AudioPlayer
            autoPlay={false}
            src={audioSrc}
            showJumpControls={true}
            showSkipControls={true}
            ref={audioRef}
            onClickNext={() => handleBothFunction('next')} 
            onClickPrevious={() => handleBothFunction('previous')}
            onEnded={handleRepeat}
          />
        </div>
      </div>
    </>
  );
};

export default AudioPlay;


