/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

const AllCourse = () => {
  return (
    <section>
    <div className="container">
        <div className="fun-facts">
            <div className="islamic-mosque" style={{backgroundImage: "url(assets/img/color-bg.jpg)"}}>
                <div className="count-style">
                  <h2 data-max="500"><sup>K+</sup></h2>
                </div>
                <h5>Downloads</h5>
                <img src="assets/img/fun-facts-1.png" alt="fun-facts" />
            </div>
            <div className="islamic-mosque" style={{backgroundImage: "url(assets/img/color-bg.jpg)"}}>
                <div className="count-style">
                  <h2 data-max="14"><sup>K</sup></h2>
                </div>
                <h5>Reading Users</h5>
                <img src="assets/img/fun-facts-2.png" alt="fun-facts" />
            </div>
            <div className="islamic-mosque mb-sm-0" style={{backgroundImage: "url(assets/img/color-bg.jpg)"}}>
                <div className="count-style">
                  <h2 data-max="317"><sup>+</sup></h2>
                </div>
                <h5>Listen Users</h5>
                <img src="assets/img/fun-facts-3.png" alt="fun-facts" />
            </div>
            <div className="islamic-mosque mb-0" style={{backgroundImage: "url(assets/img/color-bg.jpg)"}}>
                <div className="count-style">
                  <h2 data-max="26"><sup>+</sup></h2>
                </div>
                <h5>Islamic Scholars</h5>
                <img src="assets/img/fun-facts-4.png" alt="fun-facts" />
            </div>
        </div>
    </div>
</section>
  )
}

export default AllCourse 