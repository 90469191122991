import { map } from 'lodash';
import React, { useEffect, useState } from 'react';

const Showpara = ({ para,  onParaSelected ,  selectedPara}) => {
    // const [selectedParaNumber, setSelectedParaNumber] = useState('');
    console.log("selectedPara" , selectedPara);

    useEffect(() => {
        if (para && para.length > 0) {
            onParaSelected(para.id);
           
             
        }
    }, [para]);

    const handleParaSelected = (selectedPara) => {
      
        onParaSelected(selectedPara?.id);
        
    };
   

    return (
        <div>
            {map(para, (item) => (
                <div
                    className={`card bg-card mb-3 p-3 ${selectedPara === item.id ? 'bg-surahcard text-white' : 'bg-light'}`}
                    key={item.id}
                    onClick={() => handleParaSelected(item)}
                >
                    <div className="row">
                        <div className="col-2">
                            <p className={`badge ${selectedPara=== item.id ? 'active_bages text-white' : 'bg_bages'} pt-3 pb-3`} style={{ width: '45px' }}>
                                {item.id}
                            </p>
                        </div>
                        <div className="col-9 ">
                            <h5 className={`fs-6 mb-0 mx-2 ${selectedPara === item.id ? 'list_test fs-5 text-white' : ''}`}>
                                {item.englishName}
                            </h5>
                            <span className={`text-sm libraryspan mx-2 ${selectedPara === item.id ? 'text-white' : ''}`}>
                                ({item.arabicName})
                            </span>
                            <p></p>
                        </div>
                        <div className="col-2">
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Showpara;
