import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRequest } from "../Utilities/Request";

export const fetchJuzReading = createAsyncThunk('fetch/juzReading', async (juzNumber) => {
    const url = `https://api.alquran.cloud/v1/juz/${juzNumber}/ar.asad`;

    try {
        const response = await fetchRequest(url);
        return response?.data;
    } catch (error) {
        console.error(error);
        return [];
    }
});


export const fetchEnglishJuzReading = createAsyncThunk('fetch/juzEnglishReading', async (juzEnglishNumber) => {
    const url = `https://api.alquran.cloud/v1/juz/${juzEnglishNumber}/en.asad`;
    try {
        const response = await fetchRequest(url);
        return response?.data;
    } catch (error) {
        console.error(error);
        return [];
    }
});

const initialState = {
    loading:null,
    SparaData:null,
    englishSpara:null,
}


const SparaSlice = createSlice({
    name:'SparaData',
    initialState,
   reducers: { },
    
    extraReducers:(builder)=>{
        //juz getting

        builder.addCase(fetchJuzReading.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(fetchJuzReading.fulfilled , (state,action) =>{
            state.SparaData = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchJuzReading.rejected , (state)=>{
            state.loading = false;
        });

        builder.addCase(fetchEnglishJuzReading.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(fetchEnglishJuzReading.fulfilled , (state,action) =>{
            state.englishSpara = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchEnglishJuzReading.rejected , (state)=>{
            state.loading = false;
        });

    }


});

export default SparaSlice;