import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import FontSizes from '../../assets/Data/FontSizes';



const FormSelect = ({ surahList, setSelectedSurah, selectedSurah, para, onParaSelected, selectedPara,onAyatSelect   }) => {
    const [selectedSurahNumber, setSelectedSurahNumber] = useState(null);
    const [selectedAyahs, setSelectedAyahs] = useState([]);
    const [selectedAyatNumber, setSelectedAyatNumber] = useState(null);
    const [selectedJuz, setSelectedJuz] = useState(null);
 
    
// console.log("default selected Surah " , selectedSurah);
const defaultSurah = surahList.find((surah) => surah.number === selectedSurah);
// console.log("default Surah" , defaultSurah);
    useEffect(() => {
        if (surahList && surahList.length > 0) {
           
            
            if (defaultSurah) {
                setSelectedSurahNumber(defaultSurah.number);
                setSelectedAyahs(defaultSurah.ayahs || []);
                setSelectedSurah(defaultSurah);
                
            }
        }
    }, [surahList, defaultSurah, setSelectedSurah]);


    useEffect(() => {
        if (selectedSurah) {
            setSelectedSurahNumber(selectedSurah.number);
            setSelectedAyahs(selectedSurah.ayahs || []);
        }
    }, [selectedSurah]);


    const handleSurahSelect = (event) => {
        const selectedSurah = surahList.find((surah) => surah.number === parseInt(event.target.value));
        setSelectedSurahNumber(selectedSurah.number);
        setSelectedAyahs(selectedSurah.ayahs || []);
        
        setSelectedSurah(selectedSurah);
    };

    const handleAyatChange = (event) => {
        const ayatNumber = parseInt(event.target.value);
        setSelectedAyatNumber(ayatNumber);
        onAyatSelect(ayatNumber);
        
    };

    const handleJuzSelect = (event) => {
        const ayatJuz = parseInt(event.target.value);
        
        onParaSelected(ayatJuz);
    };
    

    return (
        <div className='row mb-3'>
            <div className='col-12 col-md-12 mb-3 mb-md-3'>
                <select className='form-select' aria-label='Select Surah' onChange={handleSurahSelect} 
                    value={selectedSurahNumber || ''}
                >
                    <option disabled>Select Surah</option>
                    {surahList?.map((surah) => (
                        <option key={surah.number} value={surah.number}>
                            {surah?.englishName} {surah?.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className='col-12 col-md-12  mb-3 mb-md-3'>
                <select className='form-select' aria-label='Select Ayah' onChange={handleAyatChange} >
                    <option >Select Ayah</option>
                    {map(selectedAyahs, (item, index) => (
                        <option key={index} value={item?.numberInSurah}>
                           {item?.numberInSurah}
                        </option>
                    ))}
                </select>
            </div>
            <div className='col-12 col-md-12  mb-3 mb-md-3'>
                <select className='form-select' aria-label='Select Juz' onChange={handleJuzSelect} 
                value={selectedPara || ''}
                >
                     <option >Select Juz</option>
                    {map(para, (item, index) => (
                        <option key={index} value={item?.id}>
                            {item?.englishName} ({item?.arabicName})
                        </option>
                    ))}
                </select>
            </div>
            
        </div>
    );
};

export default FormSelect;
{/* <div className='col-12 col-md-3  mb-3 mb-md-0'>
                <select className='form-select' aria-label='Select Juz' onChange={handleFontSize} >
                    <option >Select Font Size</option>
                    {map(FontSizes,(item ,index)=>(
                        <option key={index} value={item?.value} >{item?.value}</option>
                    ))}
                   
                </select>
            </div> */}
