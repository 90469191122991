import React from 'react'
import { Route ,Routes  } from "react-router-dom"
import { Home  , Terms , Policy} from '../Pages'




const AllRouter = () => {
  return (
    <Routes>
        <Route  path="/" exact element={<Home />} />
        <Route  path="/home" exact element={<Home />} />
        <Route  path="/termsandcondition"  element={<Terms />} />
        <Route  path="/privacyPolicy"  element={<Policy />} />

    </Routes>
  )
}

export default  AllRouter