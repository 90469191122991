import { map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useAppSelector from '../../Redux/Utilities/useAppSelector';
import { fetchEnglishReading, fetchJuzReading, fetchReading, setJuz } from '../../Redux/Slices/QuranReading';



const QuickFlipBook = ({ ayat, surahNumber, onAyatSelect  , arabicData , englishData,  fontSelect ,translationValue  }) => {
   
    const [selectedAyah, setSelectedAyah] = useState(null);
    const [hoveredAyah, setHoveredAyah] = useState(null);

    const handleClickAyah = (ayahNumber) => {
        setSelectedAyah(ayahNumber);
    };
    const highlightedRef = useRef(null);

    const englishTranslation = englishData?.surahs?.find((surah) => surah.number === surahNumber);
    const arabicTranslation = arabicData?.surahs?.find((surah) => surah.number === surahNumber);
    const startText = 'بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ'

    useEffect(() => {
        if (highlightedRef.current) {
            highlightedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [onAyatSelect]);

    const fontSizeStyle = {
        fontSize: typeof fontSelect === 'number' ? `${fontSelect}%` : fontSelect
    };
    // console.log("fontSize" , fontSizeStyle);

    const handleMouseEnterAyah = (ayahNumber) => {
        setHoveredAyah(ayahNumber);
    };

    // Function to handle mouse leave from an ayah
    const handleMouseLeaveAyah = () => {
        setHoveredAyah(null);
    };


    return (
        <div className='reading_surah p-2'  style={fontSizeStyle}>
            
                {surahNumber && map(ayat, (ayah) => {
                    const englishAyah = englishTranslation?.ayahs?.find((engAyah) => engAyah.numberInSurah === ayah.numberInSurah);
                    const arabicAyah = arabicTranslation?.ayahs?.find((arbAyah) => arbAyah.numberInSurah === ayah.numberInSurah);
                    const isHighlighted = onAyatSelect === ayah.numberInSurah;
                    const isStartText = arabicAyah?.text?.startsWith(startText);

                    // Split the text into the phrase and the rest of the text
                    const remainingText = isStartText ? arabicAyah?.text?.replace(startText, '') : arabicAyah?.text;
                   
                   
                    return (

                        <li
                        key={ayah.number}
                        className={`border_bottom mb-3 pb-2 ${isHighlighted ? 'highlighted' : ''}`}
                        ref={isHighlighted ? highlightedRef : null}
                        onClick={() => handleClickAyah(ayah.numberInSurah)}
                        style={{cursor: 'pointer'}}
                        onMouseEnter={() => handleMouseEnterAyah(ayah.numberInSurah)}
                        onMouseLeave={handleMouseLeaveAyah}
                      
                    >
                        <p className='text-color'>
                            {surahNumber} : {ayah?.numberInSurah}
                        </p>
                        {isStartText && (
                            <>
                                <h5 className='text-end pr-3 lh-lg  mb-1 fw-bolder' style={fontSizeStyle}>{startText}</h5>
                                <h5 className='text-end pr-3 lh-lg mb-1 fw-bolder'style={fontSizeStyle}>{remainingText}</h5>
                            </>
                        )}
                        {!isStartText && (
                            <h5 className='text-end pr-3 lh-lg  mb-1 fw-bolder' style={fontSizeStyle}>{arabicAyah?.text}</h5>
                        )}
                        {translationValue === 'none' &&  ''}
                           
                       
                        {translationValue === 'fixedTranslation' && selectedAyah === ayah.numberInSurah && (
                                <p className='english-text' style={fontSizeStyle}>{englishAyah?.text}</p>
                            )}

           {(translationValue === 'mouseOver' && (selectedAyah === ayah.numberInSurah || hoveredAyah === ayah.numberInSurah)) && (
                                <p className='english-text' style={fontSizeStyle}>{englishAyah?.text}</p>
                            )}
                        
                    </li>

                    );
                })}
                
            
                
                
               
           
        </div>
    );
};

export default QuickFlipBook;
