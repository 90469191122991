/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import { Grid } from 'react-loader-spinner';

const Preloader = () => {
  const [loading, setLoading] = useState(true);
  return (
    <>
  <div className="preloader">
    <video autoPlay loop muted className="background-video">
        <source src="assets/img/giflogo.mp4" type="video/mp4" />
 
    </video>
  
</div>
  </>
  )
}

export default Preloader