/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchEnglishJuzReading, fetchJuzReading } from '../../Redux/Slices/SparaSelected';
import useAppSelector from '../../Redux/Utilities/useAppSelector';
import { map } from 'lodash';

const SparaFlipBook = ({ ayat, onAyatSelect, translationValue, fontSelect }) => {
    const { SparaData, englishSpara } = useAppSelector((state) => state.SparaData);
    const dispatch = useDispatch();
    const highlightedRef = useRef(null);

    const [selectedAyah, setSelectedAyah] = useState(null);
    const [hoveredAyah, setHoveredAyah] = useState(null);

    const handleClickAyah = (ayahNumber) => {
        setSelectedAyah(ayahNumber);
    };

    useEffect(() => {
        if (ayat) {
            dispatch(fetchJuzReading(ayat));
            dispatch(fetchEnglishJuzReading(ayat));
        }
    }, [dispatch, ayat]);

    const paraData = SparaData?.ayahs || [];
    const englishData = englishSpara?.ayahs || [];
    const startText = 'بِسْمِ اللَّهِ الرَّحْمَٰنِ الرَّحِيمِ';

    const combinedData = map(paraData, (item) => {
        const translation = englishData.find((engItem) => engItem?.numberInSurah === item?.numberInSurah);
        return {
            ...item,
            translation: translation ? translation?.text : '',
        };
    });

    useEffect(() => {
        if (highlightedRef.current) {
            highlightedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [onAyatSelect]);

    const handleMouseEnterAyah = (ayahNumber) => {
        setHoveredAyah(ayahNumber);
    };

    const handleMouseLeaveAyah = () => {
        setHoveredAyah(null);
    };

    const fontSizeStyle = {
        fontSize: typeof fontSelect === 'number' ? `${fontSelect}%` : fontSelect,
    };

    return (
        <div className="reading_surah p-2"  style={fontSizeStyle}>
            {combinedData.map((ayah) => {
                const isHighlighted = onAyatSelect === ayah.numberInSurah;
                const isStartText = ayah?.text?.startsWith(startText);
                const remainingText = isStartText ? ayah?.text?.replace(startText, '') : ayah?.text;

                return (
                    <li
                        key={ayah.number}
                        className={`border_bottom mb-3 pb-2 ${isHighlighted ? 'highlighted' : ''}`}
                        ref={isHighlighted ? highlightedRef : null}
                        onClick={() => handleClickAyah(ayah.numberInSurah)}
                        style={{ cursor: 'pointer' }}
                        onMouseEnter={() => handleMouseEnterAyah(ayah.numberInSurah)}
                        onMouseLeave={handleMouseLeaveAyah}
                    >
                        <p className="text-color" >
                            {ayah?.surah.number} : {ayah?.numberInSurah}
                        </p>
                        {isStartText && (
                            <>
                                <h5 className='text-end pr-3 lh-lg  mb-1 fw-bolder' style={fontSizeStyle}>
                                    {startText}
                                </h5>
                                <h5 className='text-end pr-3 lh-lg  mb-1 fw-bolder' style={fontSizeStyle}>
                                    {remainingText}
                                </h5>
                            </>
                        )}
                        {!isStartText && (
                            <h5 className='text-end pr-3 lh-lg  mb-1 fw-bolder' style={fontSizeStyle}>
                                {ayah?.text}
                            </h5>
                        )}
                        {translationValue === 'none' ? '' : null}
                        {translationValue === 'fixedTranslation' && selectedAyah === ayah.numberInSurah && (
                            <p className="english-text" style={fontSizeStyle}>
                                {ayah?.translation}
                            </p>
                        )}
                        {(translationValue === 'mouseOver' &&
                            (selectedAyah === ayah.numberInSurah || hoveredAyah === ayah.numberInSurah)) && (
                            <p className="english-text" style={fontSizeStyle}>
                                {ayah?.translation}
                            </p>
                        )}
                    </li>
                );
            })}
        </div>
    );
};

export default SparaFlipBook;
