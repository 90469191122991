/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const Download = () => {
  return (
    <section className="gap">
      <div className="container">
        <div className="join-our-community" style={{backgroundImage: "url(assets/img/color-bg.jpg)"}}>
          <h2>Download the Great App and Listen Quran</h2>
          <p>The App teaches you how to Read Quran with Tajweed with the help of audio recitation and transliteration of each word of Quran.</p>
          <a href="https://play.google.com/store/apps/details?id=com.holyquran.mp3quran.quranoffline.offline.quran.reading.mp3.quran&hl=en" className="btn">Download App</a>
          <div className="join-our-img">
            <img src="assets/img/join-our-1.jpg" alt="img" />
            <img src="assets/img/join-our-2.jpg" alt="img" />
            <img src="assets/img/join-our-3.jpg" alt="img" />
            <img src="assets/img/join-our-4.jpg" alt="img" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Download;
