import React, { useState } from 'react';
import DropDowns from '../Dropdonws';
import { map } from 'lodash';
import FormSelect from '../../Reading/FormSelect';

const ReadingForm = ({ onTransaltionSelect, surahList, setSelectedSurah,  selectedSurah, para, onParaSelected, selectedPara,onAyatSelect, onjuzSelect, onFontSelect }) => {
    const [selectedTranslationOption, setSelectedTranslationOption] = useState('none');
    const [fontSize, setFontSize] = useState(100);

    const options = [
        { value: 'fixedTranslation', label: 'Fixed Translation Box' },
        { value: 'mouseOver', label: 'Translation On MouseOver' },
        { value: 'none', label: 'None' }
    ];

    const handleTranslation = (value) => {
        setSelectedTranslationOption(value);
        onTransaltionSelect(value);

    }

    const increaseFontSize = () => {
        const newSize = Math.min(fontSize + 10, 200);
        setFontSize(newSize);
        onFontSelect(newSize);
    };

    const decreaseFontSize = () => {
        const newSize = Math.max(fontSize - 10, 50);
        setFontSize(newSize);
        onFontSelect(newSize);
    };
    return (
        <div className='row mb-3'>
            <div className="col-12 col-md-4">
                <DropDowns options={
                    map(options, (item, index) => (
                        <div className="form-check" key={index}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value={item?.value}
                                name="flexRadioDefault"
                                id={`flexRadioDefault${index}`}
                                onChange={() => handleTranslation(item.value)}
                                checked={selectedTranslationOption === item?.value}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`flexRadioDefault${index}`}
                            >
                                {item.label}
                            </label>
                        </div>
                    ))
                }>
                    Translation
                </DropDowns>
            </div>
            <div className="col-12 col-md-4">
                <DropDowns options={<FormSelect
                    surahList={surahList}
                    selectedSurah={selectedSurah}
                    setSelectedSurah={setSelectedSurah}
                    onParaSelected={onParaSelected}
                    selectedPara={selectedPara}
                    para={para}
                    onAyatSelect={onAyatSelect}
                    onjuzSelect={onjuzSelect}
                   

                />}>

                    Browsers
                </DropDowns>
            </div>
            <div className="col-12 col-md-4">
                <DropDowns options={
                    <ul class="list-group">
                        <li class="list-group-item d-flex justify-content-between align-items-end">
                            Page

                            <div className='d-flex'> 
                              
                                <span  className="font-control"  onClick={decreaseFontSize}>-</span>
                              

                                <span className='mx-2 mt-1 font-percentage'> {fontSize}%</span>

                                <span className="font-control" onClick={increaseFontSize}>+</span>
                            </div>


                        </li>


                    </ul>


                }>
                    Display Option
                </DropDowns>
            </div>
        </div>
    );
}

export default ReadingForm;
