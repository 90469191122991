import React, { useEffect, useRef, useState } from 'react';
import useAppSelector from '../../Redux/Utilities/useAppSelector';
import { useDispatch } from 'react-redux';
import { fetchRecitersList, surahList } from '../../Redux/Slices/RecitersName';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { map } from 'lodash';

const Create = () => {
    const dispatch = useDispatch();
    const { recitersName, surah } = useAppSelector((state) => state.recitersName);

    // const [selectedReciter, setSelectedReciter] = useState(recitersName.length > 0 ? recitersName[0] : '');
    // const [selectedSurah, setSelectedSurah] = useState(surah.length > 0 ? surah[0] : '');

    const [selectedReciter, setSelectedReciter] = useState(recitersName?.length > 0 ? recitersName[0] : '');
    const [selectedSurah, setSelectedSurah] = useState(surah?.length > 0 ? surah[0] : '');
    const [accordionOpen, setAccordionOpen] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);

    const audioRef = useRef(null);

    const handleReciterClick = (id) => {
        const reciter = recitersName.find(reciter => reciter.id === id);
        setSelectedReciter(reciter);
        setSelectedSurah(null); // Reset selected surah when reciter changes
    }

    const handleAudioSelected = (id) => {
        console.log('surah check -----<',surah)
        const surahObj = surah.find(surah => surah?.id === id); 
        setSelectedSurah(surahObj);
    }

    const handlePlayAudio = (autoPlay = true) => {
        if (audioRef.current && audioRef.current.audio.current && selectedSurah) {
            const surahNumber = selectedSurah?.id.toString().padStart(3, '0');
            const audioUrl = `${selectedReciter.moshaf[0].server}${surahNumber}.mp3`;
            audioRef.current.audio.current.src = audioUrl;
            if(!autoPlay){
                audioRef.current.audio.current.autoplay = false;
            }else{
                audioRef.current.audio.current.autoplay = true
            }

            // Close the accordion when audio is played
            setAccordionOpen(false);
        }
    }


    useEffect(() => {
        if (surah?.length > 0) {
            handleAudioSelected(surah[0].id);
            handlePlayAudio(false)

        }
    }, []);


    const surahId = selectedReciter?.id; // ID of the selected surah
    
    const numRecitersForSurah = Array.isArray(recitersName) ? 
    recitersName.reduce((count, reciter) => {
        // Assuming moshaf is an array with at least one element
        const moshaf = reciter?.moshaf[0];
        if (moshaf && moshaf.surah_list) {
            const surahList = moshaf.surah_list.split(',');
            if (surahList.includes(surahId?.toString())) {
                return count + 1;
            }
        }
        return count;
    }, 0) : 0;
   

    useEffect(() => {
        dispatch(fetchRecitersList());
        dispatch(surahList());
    }, [dispatch]);



    return (
        <>
            <div className="card border_style">
                <div className="row ">
                    <div className="col-md-3 ">
                        <img src="assets/img/audio_image.png" alt="icon" className='w-100 object-fit-contain image_width' />
                    </div>
                    <div className="col-md-9 mt-3 pt-3  pt-md-3 pb-0 audio_section">

                        <h6 className='text-center mb-0 accordion_heading'>{selectedReciter ? selectedReciter?.name : 'RecitersName'}</h6>
                        <p className='text-center mb-0 m-0 p-0 libraryspan'>  {selectedSurah ? selectedSurah?.name : 'Surah Name'}</p>

                        {/* <audio controls ref={audioRef} className={isPlaying ? 'playing' : ''}>
                            <source src="" type="audio/mpeg" />
                        </audio> */}
                        <AudioPlayer
                            autoPlay
                            ref={audioRef}
                            onPlay={e => console.log("onPlay")}

                            showJumpControl={false}
                        // other props here
                        />

                    </div>
                </div>
                <div className="accordion rounded-0 " id="accordionExample">
                    <div className="accordion-item rounded-0 m-0">
                        <h2 className="accordion-header rounded-0 " id="headingOne">
                            <button className="accordion-button" type="button" onClick={() => setAccordionOpen(!accordionOpen)} aria-expanded={accordionOpen} aria-controls="collapseOne">
                            </button>
                        </h2>
                        <div className={`accordion-collapse collapse ${accordionOpen ? 'show' : ''}`} id="collapseOne" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="accordion-body m-0 p-0">
                                <form>
                                    <div className="row m-0 p-0">
                                        <div className="col-md-6 m-0 p-2 ">
                                            <p className='mx-2 text-black accordion_heading'>Reciters</p>
                                            <div className="card  shadow-lg mt-3 accordion_div " >
                                                {map(recitersName, (reciter, index) => (

                                                    <div className="row mt-2 p-2 pt-2 pb-2" key={index} style={{ borderBottom: "1px solid #E5E5E5" }}>
                                                        <div className='col-md-3'>
                                                            <img src="assets/img/reciters.png" className='w-md-100 d-flex mx-auto' alt="ayat" />

                                                        </div>
                                                        <div className="col-md-8  m-0  p-0 pt-2 pt-md-2 reciters_section  text-center text-md-start">
                                                            <h5 className='fs-6 mb-1 text-center text-md-start'>{reciter?.name}</h5>
                                                            <span className='text-sm libraryspan '>{reciter?.moshaf[0].surah_total} surah in library</span>
                                                        </div>
                                                        <div className="col-md-1  m-0  p-0 pt-md-3">
                                                            <input type="radio" name="reciters" className="reciters" checked={selectedReciter?.id === reciter.id} onClick={() => handleReciterClick(reciter.id)} />
                                                        </div>
                                                    </div>

                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-6 m-0 p-2 ">
                                            <p className='mx-2 text-black accordion_heading'>Surah</p>
                                            <div className="card p-2 shadow-md mt-3 accordion_div" >
                                                {selectedReciter && selectedReciter.moshaf[0] && selectedReciter.moshaf[0].surah_list.split(',').map((surahIndex, index) => {
                                                    const surahObj = surah.find(surah => surah.id === parseInt(surahIndex));


                                                    return surahObj && (

                                                        <div className="row mt-2 p-2 pt-2 pb-2" key={index} style={{ borderBottom: "1px solid #E5E5E5" }}>
                                                            <div className='col-md-3'>
                                                                <img src="assets/img/surahname.png" className='w-50 w-md-100 d-flex mx-auto' alt="ayat" />

                                                            </div>
                                                            <div className="col-md-8  m-0  p-0 pt-2 pt-md-2 reciters_section  text-center text-md-start">
                                                                <h5 className='fs-6 mb-1  text-center text-md-start'>{surahObj.name}</h5>
                                                                <span className='text-sm libraryspan  text-center text-md-start'>{numRecitersForSurah} reciters surah in library</span>
                                                            </div>
                                                            <div className="col-md-1 p-0 pt-md-3">
                                                                <input type="radio" name="audioSelected  "  className="reciters" defaultChecked={selectedSurah?.id === surahObj?.id} onChange={() => handleAudioSelected(surahObj?.id)} />
                                                            </div>
                                                        </div>

                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex mx-auto'>
                                        <div className='audio-btn border-none btn-sm justify-content-center mx-auto mt-4 mb-0' onClick={() => handlePlayAudio(true)}>Play Audio</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Create;
