import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchRequest } from "../Utilities/Request";
import sparah from "../../assets/Data";

export const fetchReading = createAsyncThunk('fetch/Reading' , async()=>{
    return await fetchRequest('https://api.alquran.cloud/v1/quran/ar.asad')
    .then((response)=>{
        return response?.data;
    }).catch((error)=>{
        console.log(error);
        return[];
    })
});


export const fetchEnglishReading = createAsyncThunk('fetch/EnglishReading' , async()=>{
    return await fetchRequest('https://api.alquran.cloud/v1/quran/en.asad')
    .then((response)=>{
        return response?.data;
    }).catch((error)=>{
        console.log(error);
        return[];
    })
});



 export const fetchParaReading = createAsyncThunk('fetch/paraReading' ,async() => {
    try 
    {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      return sparah;
   
    }catch (error) {
        return [];
      }
 });


//  juz get



const initialState = {
    loading:null,
    ReadingData: [],
    englishData:[],
    sparah:[],
   
}

const ReadingSlice = createSlice({
    name:'ReadingData',
    initialState,
   
        reducers: {
         
        setJuz(state, action) {
            state.juz = action.payload; // Update juz state
        }
    },
    
    extraReducers:(builder)=>{
        builder.addCase(fetchReading.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(fetchReading.fulfilled , (state,action) =>{
            state.ReadingData = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchReading.rejected , (state)=>{
            state.loading = false;
        });

        //english reading
        builder.addCase(fetchEnglishReading.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(fetchEnglishReading.fulfilled , (state,action) =>{
            state.englishData = action.payload.data;
            state.loading = false;
        });
        builder.addCase(fetchEnglishReading.rejected , (state)=>{
            state.loading = false;
        });

        
        builder.addCase(fetchParaReading.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(fetchParaReading.fulfilled , (state,action) =>{
            state.sparah = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchParaReading.rejected , (state)=>{
            state.loading = false;
        });

        //juz getting

      

    }


});

export default ReadingSlice;