import React from 'react';

import { map } from 'lodash';

const SurahList = ({ selectedSurah,  setSelectedSurah ,  surahList  }) => {

  const handleSurahSelect = (surah) => {
    setSelectedSurah (surah)
};

  return (

        <div className=''>
        {map(surahList , (item)=> (
          <div className={`card bg-card mb-3 p-3 ${selectedSurah?.number === item?.number ? 'bg-surahcard text-white' : 'bg-light'}`}  key={item.number} onClick={() => handleSurahSelect(item)}>
            <div className="row" >
                <div className="col-2">
                <p className={`badge ${selectedSurah?.number === item.number ? 'active_bages text-white' : 'bg_bages'} pt-3 pb-3`} style={{ width: '45px' }}>
                                {item.number}
                            </p>
                </div>
                <div className="col-9 ">
                <h5 className={`fs-6 mb-0 mx-2 ${selectedSurah?.number === item.number ? 'list_test fs-5 text-white' : ''}`}>
                                {item.englishName}
                            </h5>
                            <span className={`text-sm libraryspan mx-2 ${selectedSurah?.number === item.number ? 'text-white' : ''}`}>
                                {item.englishNameTranslation}
                            </span>
                <p></p>
                </div>
                <div className="col-2"> 
                </div>
            </div>

          </div>
        ))}
    
    
    </div>
    
    
  )
}

export default SurahList